<template
  ><div class="gestion-template-th style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="4">
          <div class="title-header">
            Gestion des templates
          </div>
        </v-col>
        <v-col cols="2">
          <v-progress-circular
            class="ml-2"
            v-if="TemplateLoadingFetch"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="3">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="getTemplateError">
              <ul v-if="Array.isArray(getTemplateError)" class="mb-0">
                <li v-for="(e, index) in getTemplateError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getTemplateError }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div
      class="box-content"
      v-if="checkPermission('GTTHAT') && selectedTemplate"
    >
      <div class="card-block-filter-setting pt-1 pb-1">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click.prevent.stop="initialisetinymce"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter une template</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="templates-block">
      <v-list class="list-category">
        <v-list-item-group>
          <div class="box-content pb-1">
            <div class="card-block-filter-setting pt-1 pb-1">
              <div class="right-block-filter"></div>
              <div class="left-block-filter">
                <v-tooltip bottom color="#311B92">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="block-icon-setting text-center mr-1"
                      v-on="on"
                      v-bind="attrs"
                      v-if="checkPermission('GTTHAC')"
                      @click.prevent.stop="openModalAddcategorie()"
                    >
                      <font-awesome-icon icon="plus-circle" />
                    </div>
                  </template>
                  <span> Ajouter une categorie</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <v-list-item
            button
            v-for="categorie in getAllTemplate"
            :key="categorie.name"
            @click.prevent="handleClickTemplate(categorie)"
            class="list-groupe-item-groupe-template"
          >
            <v-list-item-icon
              v-if="selectedTemplate && selectedTemplate.name == categorie.name"
            >
              <font-awesome-icon icon="check" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                ><span class="capitalize">{{
                  categorie.name
                }}</span></v-list-item-title
              >
            </v-list-item-content>
            <v-menu
              v-if="
                categorie &&
                  categorie.id != 0 &&
                  (checkPermission('GTTHRC') || checkPermission('GTTHSC'))
              "
              offset-y
              close-on-click
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  text
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="block-icon-reglement-fac mr-2"
                >
                  <font-awesome-icon icon="ellipsis-h" />
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title
                    @click.stop="handleUpdatecategorie(categorie)"
                    v-if="checkPermission('GTTHRC')"
                  >
                    <v-btn small class="block-icon-reglement-fac mr-2">
                      <font-awesome-icon class="icon" icon="pencil-alt"
                    /></v-btn>
                    Renommer
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click.stop="handleDeletecategorie(categorie)"
                  v-if="checkPermission('GTTHSC')"
                >
                  <v-list-item-title>
                    <v-btn small class="block-icon-reglement-fac mr-2">
                      <font-awesome-icon class="icon" icon="trash-alt"
                    /></v-btn>
                    Supprimer</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div class="mt-2 template-block-style">
        <v-list
          v-if="selectedTemplate && selectedTemplate.templates.length > 0"
        >
          <v-list-item-group>
            <v-list-item
              button
              v-for="template in selectedTemplate.templates"
              :key="template.id"
              class="content-template"
            >
              <v-list-item-content>
                <v-list-item-title
                  ><span class="title capitalize">
                    {{ template.name }}</span
                  ></v-list-item-title
                >
              </v-list-item-content>
              <div>
                <v-btn
                  small
                  class="block-icon-reglement-fac mr-2"
                  v-if="checkPermission('GTTHVT')"
                  title="Visualiser"
                  @click.prevent.stop="handleShowClick(template)"
                >
                  <font-awesome-icon class icon="eye" />
                </v-btn>
                <v-btn
                  small
                  class="block-icon-reglement-fac mr-2"
                  v-if="checkPermission('GTTHMT')"
                  title="Modifier"
                  @click.prevent.stop="handleUpdateClick(template)"
                >
                  <font-awesome-icon class icon="pencil-alt" />
                </v-btn>
                <v-btn
                  small
                  class="block-icon-reglement-fac mr-2"
                  v-if="
                    checkPermission('GTTHST') && template.not_deletable == 0
                  "
                  title="Supprimer"
                  @click.prevent.stop="handleDeleteClick(template)"
                >
                  <font-awesome-icon class icon="trash-alt" />
                </v-btn>
                <!-- <v-btn
                  small
                  class="block-icon-reglement-fac mr-2"
                    v-if="checkPermission('GTTHTDT')"
                 title="Table template"
                  @click.prevent.stop="handleTableTemplateClick(template)"
                >
                  <font-awesome-icon class icon="bars" />
                </v-btn> -->
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    <!-- AJOUTER Name categorie Modal -->
    <v-dialog
      v-model="addcategorie"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter une categorie</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addcategorie')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form class="mt-2" ref="addcategorie">
            <v-text-field
              label="Nom"
              :persistent-placeholder="true"
              v-model="categorieToAdd"
              dense
              outlined
              required
              :rules="[v => !!v || 'Nom est obligatoire']"
              class="msg-error"
              validate-on-blur
              color="#704ad1"
            >
            </v-text-field>
          </v-form>
        </v-card-text>

        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="getNewTemplateLoading"
            :disabled="getNewTemplateLoading"
            :class="{ loader: getNewTemplateLoading }"
            @click.prevent.stop="hendleSubmitcategorie"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addcategorie')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END AJOUTER Name Modal-->
    <!-- Update Name categorie Modal -->
    <v-dialog
      v-model="UpdateNamecategorieModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Renommer une catégorie</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('UpdateNamecategorieModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form
            class="mt-2"
            v-if="selectedcategorieToUpdate"
            ref="UpdateNamecategorieModal"
          >
            <v-text-field
              label="Nom"
              :persistent-placeholder="true"
              v-model="selectedcategorieToUpdate.name"
              dense
              outlined
              required
              :rules="[v => !!v || 'Nom est obligatoire']"
              class="msg-error"
              validate-on-blur
              color="#704ad1"
            >
            </v-text-field>
          </v-form>
        </v-card-text>

        <div class="messageError">
          <div v-if="getTemplateError" class="error-msg">
            <ul v-if="Array.isArray(getTemplateError)">
              <li v-for="(e, index) in getTemplateError" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getTemplateError }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="getTemplateLoading"
            :class="{ loader: getTemplateLoading }"
            @click.prevent.stop="submitUpdateNamecategorie"
            >Valider
          </v-btn>
          <v-btn
            outlined
            @click.prevent="hideModal('UpdateNamecategorieModal')"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Update Name Modal-->
    <!-- Update DELETED categorie Modal -->
    <v-dialog
      v-model="categorieDeleteModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer une categorie</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('categorieDeleteModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous certain de vouloir supprimer la categorie
              <strong class="bold-text">
                {{
                  selectedcategorieToDelete
                    ? selectedcategorieToDelete.name
                    : ''
                }}
              </strong>
              ?
            </p>
          </div>
        </v-card-text>

        <div class="messageError">
          <div v-if="getTemplateError" class="error-msg">
            <ul v-if="Array.isArray(getTemplateError)">
              <li v-for="(e, index) in getTemplateError" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getTemplateError }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="getTemplateLoading"
            :class="{ loader: getTemplateLoading }"
            :disabled="getTemplateLoading"
            @click.prevent.stop="handleModalSupprimerValider"
            >Confirmer
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('categorieDeleteModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Update DELETED Modal-->
    <!-- Update DELETED template Modal -->
    <v-dialog
      v-model="deleteTemplateModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer une Template</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('deleteTemplateModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous certain de vouloir supprimer
              <strong class="bold-text">
                {{ TemplateToDelete ? TemplateToDelete.name : '' }}</strong
              >
              ?
            </p>
          </div>
        </v-card-text>

        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="getTemplateLoading"
            :class="{ loader: getTemplateLoading }"
            :disabled="getTemplateLoading"
            @click.prevent.stop="supprimerTemplate"
            >Confirmer
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('deleteTemplateModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Update DELETED template Modal-->
    <!--show template -->
    <v-dialog
      v-model="showTemplateModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Afficher une Template</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('showTemplateModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-progress-circular
            class="ml-2"
            v-if="loading"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
          <iframe
            v-show="!loading"
            height="1100"
            width="760"
            :src="pdfsource"
          ></iframe>
        </v-card-text>

        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn outlined @click.prevent="hideModal('showTemplateModal')">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END SHOW TEMPLATE -->
    <!-- Modal New Template  -->
    <v-dialog
      v-model="showTemlplate"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
      :retain-focus="false"
      :eager="true"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter une template</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('showTemlplate')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="showTemlplate" class="mt-2">
            <v-row>
              <!-- Title -->
              <v-col col xs="12" sm="4" md="4">
                <v-text-field
                  label="Titre"
                  :rules="[v => !!v || 'Titre est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  v-model="templateToAdd.name"
                  required
                  :auto-focus="false"
                  outlined
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-text-field>
              </v-col>

              <!-- Catégorie -->
              <v-col col xs="12" sm="4" md="4">
                <!-- Catégorie  -->
                <v-select
                  label="Catégorie"
                  placeholder=""
                  :items="computedCategorie"
                  v-model="templateToAdd.category_id"
                  disabled
                  :persistent-placeholder="true"
                  item-text="text"
                  no-data-text="Aucun Catégorie trouvée"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>

              <!-- Groupe -->
              <v-col col xs="12" sm="4" md="4">
                <v-autocomplete
                  placeholder=""
                  :items="getGroupeTemplate"
                  v-model="templateToAdd.group_id"
                  required
                  :rules="[v => !!v || 'Groupe est obligatoire']"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Groupe"
                  :persistent-placeholder="true"
                  item-text="name"
                  no-data-text="Aucun Groupe trouvée"
                  item-value="id"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
              <!-- Groupe  -->
            </v-row>
            <v-row>
              <!-- Famille -->
              <v-col col xs="12" sm="12" md="12">
                <v-select
                  placeholder=""
                  :items="computedFamille"
                  v-model="templateToAdd.familles"
                  required
                  :rules="[v => !!v || 'Famille est obligatoire']"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Famille"
                  :persistent-placeholder="true"
                  item-text="text"
                  no-data-text="Aucun Famille trouvée"
                  item-value="value"
                  multiple
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :clearable="true"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <!-- Description  -->
              <v-col>
                <v-textarea
                  outlined
                  label="Description"
                  v-model="templateToAdd.description"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <!-- Description 2 -->
              <v-col>
                <v-textarea
                  outlined
                  label="Description 2"
                  v-model="templateToAdd.description2"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <div class="mb-1 ml-3 titleFields">
              Champs
              <div class="CopieMsg" v-if="copierText">
                Text copier
              </div>
            </div>
            <div class=" mb-1">
              <!-- Fields -->
              <div v-for="(variable, index) of getTemplatesFields" :key="index">
                <div class="pb-2 ml-3 titleFields">
                  {{ variable.name }}
                </div>
                <v-row class=" mb-1 ml-3">
                  <div
                    v-for="prop of variable.variables"
                    :key="prop.id"
                    @click="CopyFields(prop.value)"
                    class="filed-class"
                    :title="prop.description"
                  >
                    <div class="icon-field">☰</div>
                    <div class="value-field">
                      {{ prop.key }}
                    </div>
                    <div class="icon-copy">
                      <font-awesome-icon icon="copy" title="Copier" />
                    </div>
                  </div>
                </v-row>
              </div>
            </div>
            <v-row class="row">
              <!-- Body -->
              <v-col col xs="12" sm="12" md="12">
                <editor
                  v-if="showTemlplate"
                  v-model="templateToAdd.content"
                  :init="computedDataTinymce.init"
                  api-key="fkvny27pjoyigjbydpscwei8n0785pudavymzl4qemdm7pzg"
                ></editor>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <div class="messageError">
          <div v-if="getTemplateError" class="error-msg">
            <ul v-if="Array.isArray(getTemplateError)">
              <li v-for="(e, index) in getTemplateError" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getTemplateError }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="getNewTemplateLoading"
            :class="{ loader: getNewTemplateLoading }"
            :disabled="getNewTemplateLoading"
            @click.prevent.stop="handleSubmitTemplate"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('showTemlplate')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--END NEW MODAL  template-->
    <!-- Modal Update Template  -->
    <v-dialog
      v-model="showTemplateToUpdate"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
      :retain-focus="false"
      :eager="true"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier une template</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('showTemplateToUpdate')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-progress-circular
            class="ml-2"
            v-if="getLoadingOneTemplate"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
          <v-form
            v-if="TemplateToUpdate"
            ref="showTemplateToUpdate"
            class="mt-2"
          >
            <v-row>
              <!-- Title -->
              <v-col col xs="12" sm="4" md="4">
                <v-text-field
                  label="Titre"
                  :rules="[v => !!v || 'Titre est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  v-model="TemplateToUpdate.name"
                  required
                  :auto-focus="false"
                  outlined
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-text-field>
              </v-col>

              <!-- Catégorie -->
              <v-col col xs="12" sm="4" md="4">
                <!-- Catégorie  -->
                <v-select
                  label="Catégorie"
                  placeholder=""
                  :items="computedCategorie"
                  v-model="TemplateToUpdate.category_id"
                  disabled
                  :persistent-placeholder="true"
                  item-text="text"
                  no-data-text="Aucun Catégorie trouvée"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>

              <!-- Groupe -->
              <v-col col xs="12" sm="4" md="4">
                <v-autocomplete
                  placeholder=""
                  :items="getGroupeTemplate"
                  v-model="TemplateToUpdate.group_id"
                  required
                  :rules="[v => !!v || 'Groupe est obligatoire']"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Groupe"
                  :persistent-placeholder="true"
                  item-text="name"
                  no-data-text="Aucun Groupe trouvée"
                  item-value="id"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
              <!-- Groupe  -->
            </v-row>
            <v-row>
              <!-- Famille -->
              <v-col col xs="12" sm="12" md="12">
                <v-select
                  placeholder=""
                  :items="computedFamille"
                  v-model="TemplateToUpdate.familles"
                  required
                  :rules="[v => !!v || 'Famille est obligatoire']"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Famille"
                  :persistent-placeholder="true"
                  item-text="text"
                  no-data-text="Aucun Famille trouvée"
                  item-value="value"
                  multiple
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :clearable="true"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <!-- Description  -->
              <v-col>
                <v-textarea
                  outlined
                  label="Description"
                  v-model="TemplateToUpdate.description"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <!-- Description 2 -->
              <v-col>
                <v-textarea
                  outlined
                  label="Description 2"
                  v-model="TemplateToUpdate.description2"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <div class="mb-1 ml-3 titleFields">
              Champs
              <div class="CopieMsg" v-if="copierText">
                Text copier
              </div>
            </div>
            <div class=" mb-1">
              <!-- Fields -->
              <div v-for="(variable, index) of getTemplatesFields" :key="index">
                <div class="pb-2 ml-3 titleFields">
                  {{ variable.name }}
                </div>
                <v-row class=" mb-1 ml-3">
                  <div
                    v-for="prop of variable.variables"
                    :key="prop.id"
                    @click="CopyFields(prop.value)"
                    class="filed-class"
                    :title="prop.description"
                  >
                    <div class="icon-field">☰</div>
                    <div class="value-field">
                      {{ prop.key }}
                    </div>
                    <div class="icon-copy">
                      <font-awesome-icon icon="copy" title="Copier" />
                    </div>
                  </div>
                </v-row>
              </div>
            </div>
            <v-row class="row">
              <!-- Body -->
              <v-col col xs="12" sm="12" md="12">
                <editor
                  v-if="showTemplateToUpdate"
                  v-model="TemplateToUpdate.content"
                  :init="computedDataTinymce.init"
                  api-key="fkvny27pjoyigjbydpscwei8n0785pudavymzl4qemdm7pzg"
                ></editor>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <div class="messageError">
          <div v-if="getTemplateError" class="error-msg">
            <ul v-if="Array.isArray(getTemplateError)">
              <li v-for="(e, index) in getTemplateError" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getTemplateError }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="getTemplateLoading"
            :class="{ loader: getTemplateLoading }"
            :disabled="getTemplateLoading"
            @click.prevent.stop="handleUpdateTemplate"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('showTemplateToUpdate')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--END Update MODAL  template-->

    <!-- Colonne Template  -->
    <v-dialog
      v-model="showTableTemplateModal"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Table dynamique du template</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="resetTableTemplateModal()"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form
            v-if="templateToDisplay && templateToDisplay.Columns !== null"
          >
            <v-row class="mt-2">
              <v-col col xs="2" sm="2" md="2">
                <label for="">Couleur label</label>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <label for="">Couleur entête</label>
              </v-col>
              <v-col col xs="4" sm="4" md="4"></v-col>
              <v-col col xs="4" sm="4" md="4"></v-col>
            </v-row>
            <v-row>
              <v-col col xs="2" sm="2" md="2">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width"
                  v-model="label"
                />
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width"
                  v-model="color"
                />
              </v-col>
              <v-col col xs="4" sm="4" md="4">
                <v-text-field
                  label="Différence de largeur"
                  type="number"
                  disabled
                  v-model="restSum"
                  :persistent-placeholder="true"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col col xs="4" sm="4" md="4" class="config-check-ges-template">
                <v-checkbox
                  label="Border"
                  v-model="border"
                  color="#704ad1"
                  :value="true"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <div class="row mb-3">
              <div class="col-1"></div>
              <div class="col-1"></div>
              <div class="col-2">
                <span class="bold-text"> Code colonne</span>
              </div>
              <div class="col-3">
                <span class="bold-text"> Nom de colonne</span>
              </div>
              <div class="col-5">
                <span class="bold-text"> Largeur de colonne % </span>
              </div>
            </div>
            <draggable :list="columns">
              <div class="row" v-for="(column, index) in columns" :key="index">
                <div class="col-1">
                  <img src="@/assets/sort.png" class="sort-icon ml-2" />
                </div>
                <p class="m-0" hidden>{{ (column.index = index) }}</p>
                <div class="col-1 config-check-ges-template">
                  <v-checkbox
                    :id="'checkbox-1' + index"
                    name="checkbox-1"
                    v-model="column.check"
                    @change="CalculeRestWidth()"
                    color="#704ad1"
                    :value="true"
                    :true-value="true"
                    :false-value="false"
                    hide-details
                  ></v-checkbox>
                </div>
                <div class="col-2 ">
                  <label>{{ column.column }}</label>
                </div>
                <div class="col-3 ">
                  <v-text-field
                    :persistent-placeholder="true"
                    v-model="column.column_value"
                    outlined
                    required
                    :rules="[v => !!v || 'champ est obligatoire']"
                    validate-on-blur
                    color="#704ad1"
                    class="input-mail-commercial msg-error"
                  >
                  </v-text-field>
                </div>
                <div class="col-5">
                  <v-text-field
                    :persistent-placeholder="true"
                    v-model="column.width"
                    type="number"
                    @change="CalculeRestWidth()"
                    outlined
                    required
                    :rules="[v => !!v || 'champ est obligatoire']"
                    validate-on-blur
                    color="#704ad1"
                    class="input-mail-commercial msg-error"
                  >
                  </v-text-field>
                </div>
                <v-divider></v-divider>
              </div>
            </draggable>
          </v-form>
        </v-card-text>
        <div class="messageError">
          <div v-if="getDynamicTableErrorsGroupe" class="error-msg">
            <ul
              v-if="Array.isArray(getDynamicTableErrorsGroupe)"
              class="mb-0"
              style="list-style-type: none"
            >
              <li
                v-for="(e, index) in getDynamicTableErrorsGroupe"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getDynamicTableErrorsGroupe }}</div>
          </div>
          <div v-else class="color-message">{{ sumError }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="submitDynamicTableTemplate"
            :loading="getLodingDynamicTableGroupe"
            :disabled="getLodingDynamicTableGroupe"
            :class="{ loader: getLodingDynamicTableGroupe }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="resetTableTemplateModal()">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Colonne Template -->
  </div>
</template>
<script>
import Clipboard from 'clipboard';
import { mapActions, mapGetters } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import draggable from 'vuedraggable';
import InputColorPicker from 'vue-native-color-picker';
export default {
  name: 'Gestion-des-templates',
  data() {
    return {
      templateToAdd: {
        name: null,
        category_id: null,
        content: null,
        description: null,
        description2: null,
        familles: [],
        group_id: null
      },
      label: null,
      border: false,
      pdfsource: null,
      restSum: 0,
      sumError: null,
      color: null,
      idTemplateToSend: null,
      templateToDisplay: null,
      copierText: false,
      columns: [],
      showTemlplate: false,
      error: null,
      loading: false,
      TemplateToDelete: null,
      TemplateToUpdate: null,
      TemplateToUpdateOrigine: null,
      showTemplateToUpdate: false,
      showTableTemplateModal: false,
      selectedTemplate: null,
      Oldcategorie: null,
      TemplateToUpdatecategorie: null,
      idTemplateToUpdate: null,
      Errors: null,
      categorieToAdd: null,
      selectedcategorieToUpdate: null,
      selectedcategorieToDelete: null,
      UpdateNamecategorieModal: false,
      categorieDeleteModal: false,
      deleteTemplateModal: false,
      showTemplateModal: false,
      addcategorie: false
    };
  },

  methods: {
    ...mapActions([
      'showTemplate',
      'fetchTemplatesFields',
      'newTemplate',
      'resetErrorlTemplate',
      'setLisImage',
      'getAllImageTemplate',
      'resetErrorTemplate',
      'updateTemplate',
      'deleteTemplate',
      'fetchAllTemplates',
      'fetchTemplateLibre',
      'newCategorieTemplate',
      'updateNameCategorie',
      'deleteCategorie',
      'addRib',
      'blockunblockRib',
      'updateRib',
      'getAllSciTypeSci',
      // 'getSettingFilialeTh',
      'updateTableProductsTemplate',
      'getOneTemplateAfterUpdate',
      'fetchFamilleListForTemplate',
      'fetchAllGroupeTemplate'
    ]),
    openModalAddcategorie() {
      this.addcategorie = true;
    },
    initialisetinymce() {
      this.templateToAdd.category_id = this.selectedTemplate.id;
      this.showTemlplate = true;
    },
    hideModal(ref) {
      this[ref] = false;
      this.resetModal();
      if (ref == 'UpdateNamecategorieModal') {
        this.$refs.UpdateNamecategorieModal.reset();
      }
      if (ref == 'showTemlplate') {
        this.$refs.showTemlplate.reset();
      }
      if (ref == 'showTemplateToUpdate') {
        this.$refs.showTemplateToUpdate.reset();
      }
      if (ref == 'addcategorie') {
        this.$refs.addcategorie.reset();
      }
    },
    resetModal() {
      this.templateToAdd = {
        name: null,
        category_id: null,
        content: null,
        description: null,
        description2: null,
        group_id: null,
        familles: []
      };
      this.categorieToAdd = null;
      this.TemplateToDelete = null;
      this.resetErrorTemplate();
      this.TemplateToUpdatecategorie = null;
      this.TemplateToUpdateOrigine = null;
    },
    async handleSubmitTemplate() {
      if (this.$refs.showTemlplate.validate()) {
        const body =
          '<!DOCTYPE html><html><head><meta charset="UTF-8"> <title>' +
          this.templateToAdd.name +
          '</title></head><body>' +
          this.templateToAdd.content +
          '</body></html>';
        const response = await this.newTemplate({
          template: {
            content: body,
            name: this.templateToAdd.name,
            category_id: this.templateToAdd.category_id,
            description: this.templateToAdd.description,
            description2: this.templateToAdd.description2,
            group_id: this.templateToAdd.group_id,
            familles: this.templateToAdd.familles.map(i => i.value)
          }
        });
        if (response) {
          this.showTemlplate = false;
        }
      }
    },
    CopyFields(text) {
      // Ensure the target element exists in the DOM
      const target = document.createElement('button');
      target.innerText = 'Copy';
      document.body.appendChild(target);

      // Instantiate Clipboard with the target element
      const clipboard = new Clipboard(target, {
        text: () => text
      });
      clipboard.on('success', e => {
        this.copierText = true;
        clipboard.destroy();
        document.body.removeChild(target);
      });

      clipboard.on('error', e => {
        clipboard.destroy();
        document.body.removeChild(target);
      });

      // Simulate a click on the target element to trigger the clipboard action
      target.click();
      setTimeout(() => {
        this.copierText = false;
      }, 1000);
    },
    resetTableTemplateModal() {
      this.columns = [];
      this.sumError = null;
      this.restSum = null;
    },
    CalculeRestWidth() {
      let sum = 0;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].check === true) {
          sum += parseFloat(this.columns[i].width);
        }
      }
      this.restSum = sum;
    },
    async submitDynamicTableTemplate() {
      let bodyFormData = new FormData();
      if (this.border == true) {
        this.border = 1;
      } else {
        this.border = 0;
      }
      let sum = 0;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].check === true) {
          sum += parseFloat(this.columns[i].width);
        }
      }
      if (sum !== 100) {
        this.sumError = 'La somme des largeurs être égale a 100';
      } else {
        bodyFormData.append('template_id', this.idTemplateToSend);
        bodyFormData.append('color_bg', this.color);
        bodyFormData.append('border', this.border);
        bodyFormData.append('color', this.label);
        for (let i = 0; i < this.columns.length; i++) {
          bodyFormData.append(
            'columns[' + i + '][column]',
            this.columns[i].column
          );
          bodyFormData.append(
            'columns[' + i + '][column_value]',
            this.columns[i].column_value
          );
          bodyFormData.append('columns[' + i + '][id]', this.columns[i].id);
          bodyFormData.append(
            'columns[' + i + '][index]',
            this.columns[i].index
          );
          bodyFormData.append(
            'columns[' + i + '][width]',
            this.columns[i].width
          );
          bodyFormData.append(
            'columns[' + i + '][blocked]',
            this.columns[i].check === true ? 0 : 1
          );
        }
        const response = await this.updateTableProductsTemplate(bodyFormData);
        if (response) {
          this.showTableTemplateModal = false;
          await this.fetchAllTemplates();
          this.getAllTemplate.map(item1 =>
            item1.templates.map(item2 => {
              if (item2.id === this.idTemplateToSend) {
                this.selectedTemplate.templates.forEach(element => {
                  if (element.id === this.idTemplateToSend) {
                    element.columns = item2.columns;
                    element.color = item2.columns[0].color_bg;
                    element.label = item2.columns[0].color;
                  }
                });
              }
            })
          );
        }
      }
    },
    async handleUpdateClick(template) {
      this.TemplateToUpdate = { ...template };
      this.TemplateToUpdateOrigine = template;
      this.showTemplateToUpdate = true;
      // this.TemplateToUpdatecategorie = template.category_id;
      // const response = await this.getOneTemplateAfterUpdate({
      //   id: template.id
      // });
    },
    async handleUpdateTemplate() {
      if (this.$refs.showTemplateToUpdate.validate()) {
        const response = await this.updateTemplate({
          TemplateToUpdate: this.TemplateToUpdate,
          TemplateToUpdateOrigine: this.TemplateToUpdateOrigine
        });
        if (response.succes) {
          this.showTemplateToUpdate = false;
          this.TemplateToUpdate = null;
          this.TemplateToUpdateOrigine = null;
        }
      }
    },
    handleDeleteClick(template) {
      this.color;
      this.TemplateToDelete = template;
      this.deleteTemplateModal = true;
    },
    handleTableTemplateClick(template) {
      this.showTableTemplateModal = true;
      let newArrayToDisplay = [];
      newArrayToDisplay = template?.columns?.map(item => {
        return { ...item, check: false };
      });
      this.templateToDisplay = newArrayToDisplay;
      this.templateToDisplay?.map(item => {
        this.columns.push({
          id: item.id,
          column: item.column,
          column_value: item.column_value,
          width: item.width,
          index: item.index,
          check: item.blocked === 0 ? true : false
        }),
          (this.color = item.color_bg);
        if (item.border == 1) {
          this.border = true;
        } else {
          this.border = false;
        }
        this.label = item.color;
      });
      this.idTemplateToSend = template?.id;
      for (let i = 0; i < this?.columns?.length; i++) {
        if (this.columns[i].check === true) {
          this.restSum += parseFloat(template.columns[i].width);
        }
      }
    },
    async supprimerTemplate() {
      const response = await this.deleteTemplate({
        template: this.TemplateToDelete
      });
      if (response) {
        this.hideModal('deleteTemplateModal');
      }
    },
    async handleShowClick(Template) {
      this.loading = true;
      this.showTemplateModal = true;
      const response = await this.showTemplate(Template.id);
      if (response.success == true) {
        this.loading = false;
        this.pdfsource = response.response;
      } else {
        (this.loading = false), (this.Errors = response.error);
      }
    },
    handleClickTemplate(item) {
      this.selectedTemplate = item;
    },

    addLinetRib() {
      this.TemplateToUpdate.ribs.push({
        swift: null,
        iban: null,
        blocked: false,
        FoundRib: false,
        changeSave: false,
        newLine: true
      });
    },
    DeleteRib(index) {
      this.TemplateToUpdate.ribs.splice(index, 1);
    },
    async handleSubmitRib(index) {
      const response = await this.addRib({
        template_id: this.TemplateToUpdate.id,
        swift: this.TemplateToUpdate.ribs[index].swift,
        iban: this.TemplateToUpdate.ribs[index].iban,
        blocked: this.TemplateToUpdate.ribs[index].blocked,
        item: this.TemplateToUpdate,
        index: index
      });

      if (response == true) {
        this.TemplateToUpdate.ribs[index].FoundRib = true;
        this.TemplateToUpdate.ribs[index].changeSave = false;
        this.TemplateToUpdate.ribs[index].newLine = false;
      }
    },
    async handleUpdateRib(index) {
      const response = await this.updateRib({
        id: this.TemplateToUpdate.ribs[index].id,
        swift: this.TemplateToUpdate.ribs[index].swift,
        iban: this.TemplateToUpdate.ribs[index].iban,
        item: this.TemplateToUpdate,
        index: index
      });
      if (response == true) {
        this.TemplateToUpdate.ribs[index].FoundRib = true;
        this.TemplateToUpdate.ribs[index].changeSave = false;
      }
    },
    async BlockUnblockRibClick(index) {
      const response = await this.blockunblockRib({
        id: this.TemplateToUpdate.ribs[index].id,
        blocked: this.TemplateToUpdate.ribs[index].blocked ? false : true,
        item: this.TemplateToUpdate,
        index: index
      });

      if (response == true) {
      }
    },
    async ChangeIcon(index) {
      this.TemplateToUpdate.ribs[index].changeSave = true;
    },
    async hendleSubmitcategorie() {
      if (this.$refs.addcategorie.validate()) {
        const response = await this.newCategorieTemplate({
          name: this.categorieToAdd
        });
        if (response) {
          this.hideModal('addcategorie');
        }
      }
    },
    handleUpdatecategorie(categorie) {
      this.selectedcategorieToUpdate = { ...categorie };
      this.Oldcategorie = categorie;
      this.UpdateNamecategorieModal = true;
    },
    handleDeletecategorie(categorie) {
      this.selectedcategorieToDelete = categorie;
      this.categorieDeleteModal = true;
    },
    async submitUpdateNamecategorie() {
      const response = await this.updateNameCategorie({
        categorie: this.selectedcategorieToUpdate,
        oldCategorie: this.Oldcategorie
      });
      if (response) {
        this.hideModal('UpdateNamecategorieModal');
      }
    },
    async handleModalSupprimerValider() {
      const response = await this.deleteCategorie(
        this.selectedcategorieToDelete
      );
      if (response) {
        if (this.selectedcategorieToDelete.templates.length) {
          // this.fetchTemplateLibre({ refresh: true });
          this.selectedTemplate = null;
        }
        if (
          this.selectedcategorie &&
          this.selectedcategorie.name &&
          this.selectedcategorie.name == this.selectedcategorieToDelete.name
        ) {
          this.selectedcategorie = null;
        }

        this.hideModal('categorieDeleteModal');
      }
    }
  },
  components: {
    'v-input-colorpicker': InputColorPicker,
    Editor,
    draggable
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'getNewTemplateLoading',
      'getImageTemplateList',
      'getTemplatesFields',
      'getTemplateError',
      'getAllTemplate',
      'getTemplateLoading',
      'getLoadingRibTemplate',
      'getErrorRibTemplate',
      'getTemplateRibFound',
      'getSciTypeSci',
      'getlodingDynamicTable',
      'getDynamicTableErrors',
      'getOneTemplate',
      'getLoadingOneTemplate',
      'TemplateLoadingFetch',
      'getTemplateFamilleLoader',
      'getTemplateFamilleData',
      'getGroupeTemplate',
      'getLodingDynamicTableGroupe',
      'getDynamicTableErrorsGroupe'
    ]),
    computedFamille() {
      return this.getTemplateFamilleData;
    },
    computedAffectCulomns() {
      let newArrayToDisplay = [];
      newArrayToDisplay = this.templateToDisplay.map(item =>
        this.columns.push({
          id: item.id,
          column: item.column,
          column_value: item.column_value,
          width: item.width,
          index: item.index
        })
      );
      return newArrayToDisplay;
    },
    computedImageList() {
      return this.getImageTemplateList.map(item => {
        return { title: item.title, value: item.url };
      });
    },
    computedDataTinymce() {
      return {
        init: {
          selector: '#editor',
          init_instance_callback: function(editor) {
            var freeTiny = document.querySelector(
              '.tox-notifications-container'
            );
            if (freeTiny) {
              freeTiny.style.display = 'none';
            }
          },
          height: 450,
          fullpage_default_doctype: '<!DOCTYPE html>',
          fullpage_default_encoding: 'UTF-8',
          plugins: [
            'advlist autolink lists link image  print preview hr',
            'searchreplace visualblocks code ',
            'insertdatetime  table paste imagetools charmap'
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt',

          toolbar_mode: 'floating',
          powerpaste_allow_local_images: true,
          language: 'fr_FR',
          toolbar:
            ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify |  link image |' +
            ' bullist  numlist outdent indent  |  forecolor backcolor  |' +
            '   print preview  fullpage |' +
            ' hr charmap',
          menubar: 'favs file edit view insert format   ',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat '
            }
          },
          contextmenu: false,
          removed_menuitems: 'paste pastetext',
          block_formats: 'box=alert-box; badge=badge; footer=footer',
          formats: {
            'alert-box': {
              block: 'div',
              styles: {
                'background-color': '#f4f5f9',
                padding: '20px'
              }
            },
            footer: {
              block: 'div',
              styles: {
                'background-color': ' #c7c7c7',
                padding: '20px'
              }
            },
            badge: {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '.1rem solid transparent',
                'border-radius': '50px',
                padding: '1rem',
                'margin-top': '0px',
                'margin-bottom': '1rem',
                color: '#2276d2',
                'box-shadow': '2px 3px #d6d7da',
                'background-color': '#008cd6',
                color: 'white'
              }
            }
          },
          /* enable title field in the Image dialog*/
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          image_list: success => success(this.computedImageList),
          file_picker_types: 'image',
          images_upload_handler: async (blobInfo, success, failure) => {
            let param = new FormData();
            param.append('image', blobInfo.blob());
            this.setLisImage(param)
              .then(response => {
                success(response.data.data.url);
              })
              .catch(error => {
                failure('HTTP Error: ' + error.message);
              });
          }
        }
      };
    },

    computedCategorie() {
      let tab = this.getAllTemplate.map(item => {
        return { value: item.id, text: item.name };
      });
      return tab;
    }
  },
  async mounted() {
    this.fetchTemplatesFields();
    this.getAllImageTemplate();
    this.fetchFamilleListForTemplate();
    this.fetchAllGroupeTemplate();
    this.fetchAllTemplates();
    // this.fetchTemplateLibre();
    // await this.getSettingFilialeTh();
  }
};
</script>

<style lang="scss" scoped>
.clipboard-btn {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.capitalize {
  text-transform: capitalize;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.border-checkbox {
  margin: auto;
  display: block;
}
.active {
  color: red !important;
  cursor: pointer;
}
.sort-icon {
  height: 20px;
  width: 20px;
}

.color-message {
  color: red;
  font-size: 10px;
}
.gestion-template-th {
  .content-template {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0;
    margin-bottom: 4px;
    font-family: 'Montserrat', sans-serif;

    .btn-action {
      display: block;
      margin-right: 20px;
      white-space: nowrap;
      button {
        margin: 0 2px;
      }
    }
  }
  .title {
    width: 300px;
    font-size: 12px !important;
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif !important;
    color: #2a2a2a;
    text-transform: capitalize;
  }

  .template-block-style {
    height: calc(100vh - 165px);
    display: block;
    overflow-y: auto;
    width: 78%;
  }
  .templates-block {
    display: flex;
    justify-content: space-between;
    .list-category {
      width: 200px;
    }
    .list-groupe-item-groupe-template {
      width: 200px;
      border: 1px solid #e0e0e0;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
    }
  }
}
.color_picker_width {
  width: 35px;
  height: 25px;
  border: solid 1px;
  margin: auto;
  display: block;
}
.rib_block {
  color: #000;
  position: relative;
  overflow: visible;
  font-size: 12px;
  margin-top: 5px;
  border: 1px solid rgba(108, 117, 125, 0.3215686275);
  padding: 4px;
  background-color: #f5f4fa;
  width: 99%;
  border-radius: 4px;
  margin: 0px auto;
  .button-add-rib {
    display: flex;
  }
  .edit-action {
    width: 5%;
    display: flex;
    font-size: 7px;
    justify-content: space-between;
    height: 80px;
    align-content: center;
    align-items: center;
    margin-right: 2%;
    .action-icon {
      cursor: pointer;
    }
    .validate {
      color: green;
    }
    .cancel {
      color: #ab2525;
    }
  }
  .line-rib {
    display: flex;
    justify-content: space-between;
  }
  .rib-add-btn {
    font-size: 9px;
    min-width: 70px;
    text-align: center;
    background-color: #7a78dc;
    border-radius: 3px;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    padding: 5px;
    color: #ffffff;
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
</style>
<style lang="scss">
.titleFields {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #2a2a2a;
  .CopieMsg {
    margin-left: 50px;
    color: #704ad1;
    font-weight: 12px;
  }
}
.filed-class {
  border: 1px solid #6c757d4d;
  background-color: #ebebeb5c;
  border-radius: 4px;
  position: relative;
  padding: 5px;
  width: 211px;
  margin: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #2a2a2a;

  .icon-field {
    position: absolute;
    left: 6px;
    width: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .icon-copy {
    position: absolute;
    right: 6px;
    width: 25px;
    top: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .value-field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 25px;
  }
}
.width-custom-modal {
  .modal-dialog {
    max-width: 530px;
  }
}
.tox-notifications-container {
  display: none !important;
}
.ModelClass {
  border-radius: 5px;
  max-width: 800px;
  width: auto;
  .vm-title {
    font-size: 1.25rem;
  }
}
.template-block-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.template-block-style::-webkit-scrollbar-track,
template-style {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.template-block-style::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 7px;
}
.style-half-circle-loder {
  padding-top: 0px !important;
  background-color: #fff !important;
}
</style>
